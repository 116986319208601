import { render, staticRenderFns } from "./UploadIdentity.vue?vue&type=template&id=c79e1dac&scoped=true"
import script from "./UploadIdentity.vue?vue&type=script&lang=js"
export * from "./UploadIdentity.vue?vue&type=script&lang=js"
import style0 from "./index.css?vue&type=style&index=0&id=c79e1dac&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c79e1dac",
  null
  
)

export default component.exports